import { Link } from 'gatsby';
import React, { FunctionComponent, memo } from 'react';
import { IReviewEntry } from '../../models/review-entry';
import { useStore } from '../../store/store';
import { useTranslation } from '../../utils/i18n';
import AmazonAffiliateInfo from '../amazon-affiliate-info/amazon-affiliate-info';
import styles from './review-side-bar.module.scss';

interface IProps {
  relatedPosts: IReviewEntry[];
}

const ReviewSideBar: FunctionComponent<IProps> = memo(({ relatedPosts }) => {
  const lang = useStore((s) => s.vm.lang);
  const { t } = useTranslation();

  return (
    <div className={styles.reviewSideBar}>
      <AmazonAffiliateInfo />
      <div className={styles.stickyWrapper}>
        <h2>{t('review.related_post')}</h2>
        {relatedPosts.map((post) => (
          <Link
            key={post.id}
            className={styles.relatedPost}
            to={`/${lang}/${post.basePath}-${lang}/`}
          >
            <div style={{ backgroundImage: `url(${post.cover})` }} />
            <h5>{post.title}</h5>
          </Link>
        ))}
      </div>
    </div>
  );
});

export default ReviewSideBar;
