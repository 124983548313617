import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatTime = (timeStr: string): string => {
  try {
    return dayjs(timeStr).format('MMM D, YYYY');
  } catch {
    return '';
  }
};

export const fromNow = (timeStr: string): string => {
  try {
    return dayjs().from(dayjs(timeStr), true);
  } catch {
    return '';
  }
};
