import React, { FunctionComponent, memo } from 'react';
import Layout from '../components/layout/layout';
import MultiLangTags from '../components/multi-lang-tags/multi-lang-tags';
import OpenGraphTags from '../components/open-graph-tags/open-graph-tags';
import PostCommentCount from '../components/post-comment-count/post-comment-count';
import PostComment from '../components/post-comment/post-comment';
import PostShareIcons from '../components/post-share-icons/post-share-icons';
import PostTags from '../components/post-tags/post-tags';
import ReviewPostBanner from '../components/review-post-banner/review-post-banner';
import ReviewSideBar from '../components/review-side-bar/review-side-bar';
import { renderRichContent } from '../components/rich-content/rich-content';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import { Locale } from '../models/locale';
import { IReviewEntry } from '../models/review-entry';
import { buildCanonicalUrl } from '../utils/define';
import { useTranslation } from '../utils/i18n';
import { formatTime, fromNow } from '../utils/time';
import styles from './review-post.module.scss';

interface IProps {
  pageContext: {
    review: IReviewEntry;
    relatedPosts: IReviewEntry[];
    intl: {
      language: Locale;
    };
  };
}

const ReviewPost: FunctionComponent<IProps> = memo(({ pageContext }) => {
  const { t } = useTranslation();
  const { intl, review } = pageContext;
  const canonicalUrl = buildCanonicalUrl(review.path, intl.language);
  const { updatedAt, createdAt } = review;
  const postCreatedDateStr = formatTime(createdAt);
  const postUpdatedDateStr = formatTime(updatedAt);

  return (
    <Layout lang={intl.language} basePath={review.basePath}>
      <SeoMetaTags
        lang={intl.language}
        title={`${review.title} | Beat Panda`}
        description={review.summary}
        canonicalUrl={canonicalUrl}
      />
      <MultiLangTags path={review.path} basePath={review.basePath} />
      <OpenGraphTags
        url={canonicalUrl}
        title={review.title}
        description={review.summary}
        image={review.cover}
        locale={intl.language}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12 order-0'>
            <div className={styles.entryHeader}>
              <h1 className={styles.entryTitle}>{review.title}</h1>
              <div className={`${styles.entryMeta} text-muted`}>
                <span>
                  {t('post.posted')} {postCreatedDateStr}
                </span>
                {createdAt !== updatedAt && (
                  <>
                    <span>|</span>
                    <span>
                      {t('post.last_updated')} {postUpdatedDateStr}
                    </span>
                  </>
                )}
                <span>|</span>
                <a href={`#comments`}>
                  <PostCommentCount identifier={review.id} url={canonicalUrl} />
                </a>
              </div>
              <div className={styles.bannerWrapper}>
                <ReviewPostBanner
                  cover={review.cover}
                  alt={`Review Post Banner of ${review.title}`}
                  height={review.coverHeight}
                  width={review.coverWidth}
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4 order-1 order-md-2'>
            <ReviewSideBar relatedPosts={pageContext.relatedPosts} />
          </div>
          <div className='col-12 col-md-8 col-lg-8 order-2 order-md-1'>
            {review.content && (
              <article>
                <div className='w-100'>{renderRichContent(review.content)}</div>
              </article>
            )}
            <div className={styles.postTags}>
              <PostTags tags={review.tags} searchDomain='/review' />
            </div>
            <div className={styles.postFooter}>
              <span>{t('post.share_on')}</span>
              <PostShareIcons title={review.title} path={review.path} lang={intl.language} />
            </div>
            <div id='comments'>
              <PostComment identifier={review.id} url={canonicalUrl} title={review.title} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
});

export default ReviewPost;
