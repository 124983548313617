import React, { FunctionComponent, memo, useEffect } from 'react';
import { Locale } from '../../models/locale';
import { buildCanonicalUrl } from '../../utils/define';
import { useTranslation } from '../../utils/i18n';

import styles from './post-share-icons.module.scss';

interface IProps {
  title: string;
  lang: Locale;
  path: string;
}

const PostShareIcons: FunctionComponent<IProps> = memo(({ title, lang, path }) => {
  const { t } = useTranslation();
  const twitterShareConfig = {
    text: encodeURIComponent(`Hey! check this out! ${title}`),
    url: `${buildCanonicalUrl(path, lang)}/`,
    via: 'BeatPandaMusic',
  };
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${twitterShareConfig.text}&url=${twitterShareConfig.url}&via=${twitterShareConfig.via}`;
  const facebookShareLink = `http://www.facebook.com/sharer.php?u=${twitterShareConfig.url}`;
  const redditShareLink = `http://www.reddit.com/submit?url=${twitterShareConfig.url}&title=${title}`;
  const pocketShareLink = `https://getpocket.com/edit?url=${twitterShareConfig.url}`;

  return (
    <ul className={styles.shareIcons}>
      <li className={styles.twitter} title={t('post.share_icon.share_twitter_title')}>
        <a href={twitterShareLink} rel='nofollow' target='_blank'>
          <i className='fab fa-twitter' />
        </a>
      </li>
      <li className={styles.facebook} title={t('post.share_icon.share_facebook_title')}>
        <a href={facebookShareLink} rel='nofollow' target='_blank'>
          <i className='fab fa-facebook' />
        </a>
      </li>
      <li className={styles.reddit} title={t('post.share_icon.share_reddit_title')}>
        <a href={redditShareLink} rel='nofollow' target='_blank'>
          <i className='fab fa-reddit' />
        </a>
      </li>
      <li className={styles.pocket} title={t('post.share_icon.share_pocket_title')}>
        <a href={pocketShareLink} rel='nofollow' target='_blank'>
          <i className='fab fa-get-pocket' />
        </a>
      </li>
    </ul>
  );
});

export default PostShareIcons;
