import { Link } from 'gatsby';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from '../../utils/i18n';
import styles from './amazon-affiliate-info.module.scss';

interface IProps {}

const AmazonAffiliateInfo: FunctionComponent<IProps> = memo(({}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.amazonAffiliateInfo}>
      <i>{t('review.amazon_affiliate_info')}</i>&nbsp;&nbsp;&nbsp;
      <Link to='/disclaimer/'>{t('review.learn_more')}</Link>
    </div>
  );
});

export default AmazonAffiliateInfo;
