import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import { Text } from '@contentful/rich-text-types/dist/types/types';
import React, { ReactNode } from 'react';
import { Link, useTranslation } from '../../utils/i18n';
import styles from './rich-content.module.scss';

const options: Options = {
  renderNode: {
    [BLOCKS.QUOTE]: (node, children) => {
      const isHighlight =
        ((node.content[0] as Block).content[0] as Text).value.trim() === '#highlight';
      if (isHighlight) {
        return (
          <div className={styles.highlight}>
            {node.content.slice(1).map((c) => (
              <p>{((c as Block).content[0] as Text).value}</p>
            ))}
          </div>
        );
      }

      return (
        <div className={styles.quote}>
          {children}
          <i className='fas fa-quote-right' />
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const src = node.data?.target?.fields?.file?.en?.url;
      const title = node.data?.target?.fields?.title?.en;
      const description = node.data?.target?.fields?.description?.en;
      return (
        <figure className={styles.image}>
          <img src={src} alt={title} />
          {description && <figcaption>{description}</figcaption>}
        </figure>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      const isNav =
        (((node.content[0] as Block).content[0] as Block).content[0] as Text).value.trim() ===
        '#navigation';
      if (isNav) {
        return (
          <ul>
            {node.content.slice(1).map((c) => {
              const [text, href] = (((c as Block).content[0] as Block)
                .content[0] as Text).value.split('#');
              return (
                <li key={href}>
                  <a href={`#${href}`}>
                    <p>{text}</p>
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }
      return <ul>{children}</ul>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      const [text, id] = (node.content[0] as Text).value.split('#');
      if (id) {
        return (
          <h2 id={id}>
            {text}
            <a href={`#${id}`} className={styles.headerLink} title={text}>
              <i className='fas fa-link' />
            </a>
          </h2>
        );
      }
      return <h2 title={text}>{children}</h2>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = node.data.uri;
      const isExternal = /^http/.test(uri);
      if (isExternal) {
        return (
          <a className={styles.hyperLink} href={uri} rel='nofollow' target='_blank'>
            {children}
          </a>
        );
      }

      const { lang } = useTranslation();
      return (
        <Link className={styles.hyperLink} to={`${uri}-${lang}`}>
          {children}
        </Link>
      );
    },
  },
  // renderText
};

const renderRichContent = (json: Document): ReactNode => (
  <div className={styles.richContentWrapper}>{documentToReactComponents(json, options)}</div>
);

export { renderRichContent };
