import React, { FunctionComponent, memo } from 'react';
import styles from './review-post-banner.module.scss';

interface IProps {
  cover: string;
  alt: string;
  height: number;
  width: number;
}

const ReviewPostBanner: FunctionComponent<IProps> = memo(({ cover, alt, height, width }) => {
  return (
    <div className={styles.reviewPostBanner}>
      {height >= width && <div style={{ backgroundImage: `url(${cover})` }} />}
      {height < width && <img src={cover} alt={alt} />}
    </div>
  );
});

export default ReviewPostBanner;
