import React, { FunctionComponent, memo, useEffect } from 'react';
import { useTranslation } from '../../utils/i18n';
import styles from './post-comment-count.module.scss';

interface IProps {
  identifier: string;
  url: string;
  showWording?: boolean;
}

const PostCommentCount: FunctionComponent<IProps> = memo(
  ({ identifier, url, children, showWording = true }) => {
    const { t } = useTranslation();
    useEffect(() => {
      // @ts-ignore
      const loaded = !!window.DISQUSWIDGETS;
      if (!loaded) {
        const script = document.createElement('script');
        script.src = 'https://beatpandamusic.disqus.com/count.js';
        script.id = 'dsq-count-scr';
        document.body.appendChild(script);
      } else {
        // @ts-ignore
        window.DISQUSWIDGETS.getCount({ reset: true });
      }
    }, [identifier]);

    return (
      <>
        <span
          className={`disqus-comment-count ${styles.postComment}`}
          data-disqus-identifier={identifier}
          data-disqus-url={url}
        />
        {showWording && <span> {t('post.comments')}</span>}
      </>
    );
  },
);

export default PostCommentCount;
