import React, { FunctionComponent, memo } from 'react';
import { Link, useTranslation } from '../../utils/i18n';
import styles from './post-tags.module.scss';

interface IProps {
  tags: string[];
  searchDomain: string;
}

const PostTags: FunctionComponent<IProps> = memo(({ tags, searchDomain }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.postTags}>
      {tags.map((tag) => {
        const searchUrl = `${searchDomain}?tag=${encodeURIComponent(tag)}`;
        return (
          <span key={tag} title={t('post.tag_icon.title', { tag })} className={styles.tag}>
            <Link to={searchUrl}>{tag}</Link>
          </span>
        );
      })}
    </div>
  );
});

export default PostTags;
