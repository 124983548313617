import React, { FunctionComponent, memo, useEffect } from 'react';
import styles from './post-comment.module.scss';

interface IProps {
  title: string;
  identifier: string;
  url: string;
}

const PostComment: FunctionComponent<IProps> = memo(({ identifier, url, title }) => {
  useEffect(() => {
    // @ts-ignore
    window.disqus_config = function() {
      // @ts-ignore
      this.page.url = url;
      // @ts-ignore
      this.page.identifier = identifier;
      // @ts-ignore
      this.page.title = title;
    };
    const script = document.createElement('script');
    script.src = 'https://beatpandamusic.disqus.com/embed.js';
    script.setAttribute('data-timestamp', new Date().toString());
    document.body.appendChild(script);
  }, []);

  return (
    <div className={styles.comments}>
      <div id='disqus_thread' />
      <noscript>
        Please enable JavaScript to view the
        <a href='https://disqus.com/?ref_noscript'>comments powered by Disqus.</a>
      </noscript>
    </div>
  );
});

export default PostComment;
